<template>
	<b-modal
		id="UploadMaterialModal"
		:visible="modalVisibility(modalId)"
		:title="`${textCreateMode(createMode)} Material`"
		@show="onShow()"
		@hidden="resetModal()"
		size="lg"
		no-close-on-backdrop
		hide-footer
		ok-title="Submit"
	>
		<upload-material-form
			:create-mode="createModeParse"
			:selected-material="selectedMaterial"
		></upload-material-form>
	</b-modal>
</template>

<script>
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";

import UploadMaterialForm from "@/view/pages/globaladmin/material/forms/UploadMaterialForm.vue";

export default {
	components: { UploadMaterialForm },
	props: {
		createMode: { type: Boolean, default: false },
	},
	data() {
		return {
			modalId: "UploadMaterialModal",
			selectedMaterial: null,
			createModeParse: null,
		};
	},
	methods: {
		textCreateMode(mode) {
			if (mode) {
				return "Upload";
			} else {
				return "Update";
			}
		},
		async onShow() {
			this.selectedMaterial = this.$parent.selectedMaterial;
			this.createModeParse = this.createMode;
		},
		resetModal() {
			this.selectedMaterial = null;
			this.createModeParse = null;
			this.$store.dispatch(SET_MODAL_STATE, null);
		},
	},
};
</script>
