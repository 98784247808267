<template>
	<div>
		<b-row>
			<b-col>
				<b-row>
					<b-col>
						<base-input
							type="text"
							v-model="form.category"
							label="Category"
							:validator="$v.form.category"
							:messages="localMessages"
						></base-input>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<base-input
							type="text"
							v-model="form.material_name"
							label="Name"
							:validator="$v.form.material_name"
							:messages="localMessages"
						></base-input>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<base-input
							type="textarea"
							v-model="form.description"
							label="Description"
							:validator="$v.form.description"
							:messages="localMessages"
						></base-input>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<label v-if="createMode">Upload new material</label>
						<label v-if="!createMode">Update material</label>
						<b-form-file
							v-model="file"
							:class="{ 'is-invalid': $v.file.$invalid }"
							ref="file-input"
							accept=".pdf, .mp4 "
							placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..."
							class="bayo-form-file w-100"
						></b-form-file>
					</b-col>
					<b-col class="mt-8" cols="2" v-if="file">
						<b-button @click="resetFile()" variant="danger" block>
							Reset
						</b-button>
					</b-col>
					<b-col
						cols="2"
						v-if="form.file_type == 'pdf' && !file"
						class="mt-8"
					>
						<b-button
							@click="onPreviewPdf(CurrentMaterial.link)"
							variant="primary"
							class="w-100"
						>
							Preview
						</b-button>
					</b-col>
				</b-row>
			</b-col>

			<b-col v-if="form.file_type == 'mp4'">
				<label>Preview</label>
				<video :key="CurrentMaterial.link" class="w-100" controls>
					<source :src="CurrentMaterial.link" type="video/mp4" />
					Something is wrong with the video
				</video>
			</b-col>
		</b-row>

		<b-row class="mt-6">
			<b-col class="d-flex flex-row-reverse">
				<b-button
					@click="onSubmit"
					class="ml-3"
					variant="primary"
					size="lg"
					>Submit</b-button
				>
				<b-button
					variant="secondary"
					size="lg"
					@click="closeModal('QuestionAnswerModal')"
					>Cancel</b-button
				>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	GET_MATERIAL_LIST,
	UPDATE_MATERIAL,
	UPLOAD_MATERIAL,
	GET_GA_VIEW_MATERIAL,
} from "@/core/services/store/modules/globaladmin/trainingcourse.module";

import SystemHelper from "@/core/services/systemhelper.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
	mixins: [validationMixin],
	props: {
		createMode: { type: Boolean, default: false },
		selectedMaterial: { type: Object },
	},
	data() {
		return {
			form: {
				material_name: "",
				description: "",
				category: "",
			},
			file: null,
		};
	},
	validations: {
		form: {
			material_name: { required },
			description: { required },
			category: { required },
		},
		file: { required },
	},
	computed: {
		CurrentMaterial() {
			const material = this.$store.state.globaladmin_trainingcourse
				.gaViewMaterial;
			if (typeof material === "object" && Object.keys(material).length) {
				return material;
			} else {
				return {};
			}
		},
	},
	methods: {
		getFileType(file) {
			let ianaFileType = file.name;
			let x = ianaFileType.split(".").pop();
			let fileType;

			if (x === "mp4") {
				fileType = "vid";
			} else {
				fileType = x;
			}
			return fileType;
		},
		onPreviewPdf(link) {
			window.open(link);
		},
		resetFile() {
			this.$refs["file-input"].reset();
		},
		onSubmit() {
			if (this.file === null) {
				return;
			} else {
				let formData = new FormData();

				formData.append("file_name", this.file);
				formData.append("material_name", this.form.material_name);
				formData.append("description", this.form.description);
				formData.append("category", this.form.category);
				formData.append("file_type", this.getFileType(this.file));
				// formData.append("savedLocationWithFileName", "material");

				if (!this.createMode) {
					formData.append("material_id", this.form.material_id);
					SystemHelper.confirmationDialogHandler(
						{ title: "Confirmation", html: "Update material?" },
						{ action: UPDATE_MATERIAL, payload: formData },
						[
							{
								action: GET_MATERIAL_LIST,
								payload: this.defaultPayload,
							},
						],
						this.$parent.modalId
					);
				} else {
					SystemHelper.confirmationDialogHandler(
						{ title: "Confirmation", html: "Upload material?" },
						{ action: UPLOAD_MATERIAL, payload: formData },
						[
							{
								action: GET_MATERIAL_LIST,
								payload: this.defaultPayload,
							},
						],
						this.$parent.modalId
					);
				}
			}
		},
	},
	async mounted() {
		if (!this.createMode) {
			let selectedMaterial = JSON.parse(
				JSON.stringify(this.selectedMaterial)
			);
			this.form = selectedMaterial;
			console.log("Form: ", this.form);

			// get material data
			const payload = {
				material_id: this.form.material_id,
				file_type: this.form.file_type,
			};
			await this.$store.dispatch(GET_GA_VIEW_MATERIAL, payload);
		}
	},
};
</script>
