<template>
  <div>
    <KTCard title="Material List">
      <template v-slot:toolbar>
        <b-button
          @click="(createMode = true), openModal('UploadMaterialModal')"
          variant="primary"
          size="sm"
          ><i class="fa fa-plus"></i> Create Material
        </b-button>
      </template>

      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          show-empty
          hover
          :items="tableItems"
          :fields="fields"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button @click="onView(row.item)" variant="primary" size="sm">
                Details
              </b-button>
              <b-button @click="onDelete(row.item)" variant="danger" size="sm">
                Delete
              </b-button>
            </b-button-group>
          </template>
          <template v-slot:cell(file_type)="row">
            <template v-if="row.item.file_type === 'pdf'">
              <i class="far fa-file-pdf text-dark"></i>
              {{ row.item.file_type }}
            </template>
            <template v-if="row.item.file_type === 'mp4'">
              <i class="fa fa-file-video text-dark"></i>
              {{ row.item.file_type }}
            </template>
            <template v-if="row.item.file_type === 'pptx'">
              <i class="far fa-file-powerpoint text-dark"></i>
              {{ row.item.file_type }}
            </template>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <upload-material-modal :create-mode="createMode"></upload-material-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import SystemHelper from "@/core/services/systemhelper.service";
import {
  DELETE_MATERIAL,
  GET_MATERIAL_LIST,
} from "@/core/services/store/modules/globaladmin/trainingcourse.module";
import UploadMaterialModal from "@/view/pages/globaladmin/material/modals/UploadMaterialModal";

export default {
  components: { UploadMaterialModal },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Global Admin" },
      { title: "Material" },
    ]);
    this.$store.dispatch(GET_MATERIAL_LIST, this.defaultPayload);
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.globaladmin_trainingcourse.allMaterialList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        pagination: true,
        refresh: false,
        filter: true,
      };
    },
    tableItems() {
      const list = this.$store.state.globaladmin_trainingcourse.allMaterialList;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      selectedMaterial: null,
      createMode: false,
      filter: "",
      currentPage: 1,
      fields: [
        {
          key: "material_id",
        },
        {
          key: "material_name",
        },
        {
          key: "file_type",
        },
        {
          key: "category",
        },
        {
          label: "Created",
          key: "created_at",
        },
        {
          key: "actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onView(rowItem) {
      this.createMode = false;
      console.log(rowItem);
      this.selectedMaterial = rowItem;
      this.openModal("UploadMaterialModal");
    },
    onDelete(rowItem) {
      console.log(rowItem);
      this.selectedMaterial = rowItem;

      let payload = {
        material_id: rowItem.material_id,
      };
      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Delete Question?" },
        { action: DELETE_MATERIAL, payload: payload },
        [{ action: GET_MATERIAL_LIST, payload: this.defaultPayload }],
        null
      );
    },
  },
};
</script>
